@import "../../assets/styles/reset";
@import "../../assets/styles/globals";
@import "../../assets/styles/icons";

.product-tile {
    background: white;
    box-shadow: $defaultBoxShadow;
    padding-bottom: 100%;
    position: relative;
    line-height: 1.2;

    .assistant-mobile-layout .TwoInRow & {
        padding-bottom: 64%;
    }

    .assistant-mobile-layout .ThreeInRow & {
        padding-bottom: 98%;
    }

    &.selected-product {
        box-shadow: $selectedBoxShadow;
        border: 2px solid rgba($colorPrimary, 0.3);
    }
    .assistant-mobile-layout .TopProduct & {
        padding-bottom: 65%;
        &::after {
            content: 'Top product';
            position: absolute;
            white-space: nowrap;
            top: 10px;
            left: 10px;
            background: $colorPrimary;
            color: white;
            padding: 6px 10px;
            border-radius: 6px;
        }
    }

    &-inner-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &-front {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-back {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $colorPrimary;
        color: white;
    }

    &-picture {
        max-height: 80%;
        max-width: 80%;

        .TwoInRow & {
            .assistant-mobile-layout & {
                max-height: 60%;
                max-width: 60%;
            }
        }

        .TopProduct & {
            .assistant-mobile-layout & {
                max-height: 60%;
                max-width: 60%;
            }
        }
    }

    &-name {
        display: block;
        margin-top: 20px;
        padding: 0 20px;
        text-align: center;

        .ThreeInRow & {
            .assistant-mobile-layout & {
                display: none;
            }
        }

        .TwoInRow & {
            .assistant-mobile-layout & {
                margin-top: 8px;
                padding: 0 10px;
            }
        }

        .TopProduct & {
            .assistant-mobile-layout & {
                margin-top: 8px;
                padding: 0 10px;
            }
        }
    }

    &-back-name {
        padding: 0 10px;
        margin-bottom: 10px;
        text-align: center;

        .SixInRow & {
            margin-bottom: 0;
            padding: 0 3px;
        }

        .ThreeInRow & {
            margin-bottom: 20px;
            .assistant-mobile-layout & {
                margin-bottom: 0;
            }
        }

        .TwoInRow & {
            margin-bottom: 30px;
            .assistant-mobile-layout & {
                font-size: 1.1em;
                margin-bottom: 0;
            }
        }
    }

    &-attributes {
        display: none;
        font-size: 0.8em;
        padding: 0 10px 10px 10px;
        width: 100%;
        font-weight: 300;

        @media screen and (min-width: $xlarge) {
            display: block;
        }

        .ThreeInRow & {
            padding: 0 20px 20px 20px;
        }

        .TwoInRow & {
            padding: 0 20px 30px 20px;
            .assistant-mobile-layout & {
                font-size: 1.1em;
                padding: 10px;
                display: block;
            }
        }

        &-item {
            line-height: 1.6;
            padding-left: 10px;
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 3px;
                height: 3px;
                background: white;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }

    &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;

        &-icon {
            @extend %iconBuyArrow;
            display: inline-block;
            margin-left: 5px;
            transform: translate(0, 1px);
        }

        .SixInRow & {
            display: none;
        }

        .ThreeInRow & {
            .assistant-mobile-layout & {
                display: none;
            }
        }

        .TwoInRow & {
            .assistant-mobile-layout & {
                display: none;
            }
        }
    }
}

/* entire container, keeps perspective */
.product-tile-wrapper, .product-tile-wrapper-mobile {
    -webkit-perspective: 1000;
    background: white;
}


/* flip the pane when clicked */
.product-tile-wrapper-mobile .flipper, .product-tile-wrapper-mobile.hover .flipper {
    -webkit-transform: rotateY(180deg);
}

.flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    position: relative;
}

.product-tile-front, .product-tile-back {
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.product-tile-front {
    z-index: 2;
}

.product-tile-back {
    -webkit-transform: rotateY(180deg);
}

@media(min-width: 980px) {
    /* flip the pane when clicked */
    .product-tile-wrapper:hover .flipper, .product-tile-wrapper.hover .flipper {
        -webkit-transform: rotateY(180deg);
    }
}

