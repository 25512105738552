@import '../../assets/styles/reset';
@import '../../assets/styles/globals';
@import '../../assets/styles/icons';

.scrollable {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    flex-direction: row;
    user-select: none;
    cursor: grab;
    padding-right: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.tags {
    padding: 10px 40px 10px 10px;
    position: relative;
    min-height: 50px;

    .assistant-mobile-layout & {
        min-height: 40px;
        padding: 5px 40px 5px 10px;
        border-bottom: 1px solid $colorLightGray;
        color: $colorDarkGray;
        background: white;
        line-height: 1.2;
    }

    &-single-item {
        display: inline-block;
        align-items: center;
        font-size: 12px;
        border-radius: 20px;
        background: white;
        padding: 8px 20px;
        border: 1px solid $colorGray;
        color: $colorGray;
        white-space: nowrap;
        margin-left: 10px;
        text-transform: lowercase;

        &-last {
            width: 10px;
            height: 10px;
        }
    }

    &-refresh {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 50px;
        box-shadow: $leftBoxShadow;

        &-button {
            @extend %iconRefresh;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &-refresh-mobile {
        box-shadow: none;
        height: 42px;
    }


    &-mobile-header {
        font-size: 13px;
        color: $colorGray;
        padding-top: 10px;
    }
}

.tags-mobile {


    &-header {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
