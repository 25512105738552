@import '../../assets/styles/globals';

$productTileMargin: 4px;
$widthReducer: 2 * 4px;
@media screen and (min-width: $large) {
    $productTileMargin: 10px;
    $widthReducer: 2 * 10px;
}

.last-step-header {
    display:flex;
    justify-content: center;
    font-size: 26px;
    margin-bottom: 30px;
    color: #333333;
}

$tileMargin: 4px;
@media screen and (min-width: $large) {
    $tileMargin: 10px;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-1 * $productTileMargin);

    &-item {
        width: 100%;
        font-size: 1.2vw;
        margin: 0 $tileMargin $tileMargin;
        cursor: pointer;

        &.SixInRow {
            width: calc(16.666% - #{$widthReducer});
            font-size: 1.8vw;
            margin: 0 $tileMargin $tileMargin;
        }

        &.FiveInRow {
            width: calc(20% - #{$widthReducer});
            font-size: 1vw;
            margin: 0 $tileMargin $tileMargin;
        }

        &.FourInRow {
            width: calc(25% - #{$widthReducer});
            font-size: 1vw;
            margin: 0 $tileMargin $tileMargin;
        }

        &.ThreeInRow {
            width: calc(33.333% - #{$widthReducer});
            font-size: 1.2vw;
            margin: 0 $tileMargin $tileMargin;

            .assistant-mobile-layout & {
                font-size: 3vw;
            }
        }

        &.TwoInRow {
            width: calc(50% - #{$widthReducer});
            font-size: 1.2vw;
            margin: 0 $tileMargin $tileMargin;

            .assistant-mobile-layout & {
                font-size: 2.2vw;
            }
        }

        &.TopProduct {
            width: calc(33.333% - #{$widthReducer});
            font-size: 1.2vw;
            margin: 0 $tileMargin $tileMargin;

            .assistant-mobile-layout & {
                font-size: 3vw;
                width: calc(100% - #{$widthReducer});
            }
        }
    }
}
