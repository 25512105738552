@import "../../assets/styles/reset";
@import "../../assets/styles/globals";
@import "../../assets/styles/icons";

.message-input {
    padding: 0 30px;
    position: relative;

    .assistant-mobile-layout & {
        padding: 0 10px 0 10px;
    }

    &-element {
        height: 75px;
        background: white;
        border: none;
        border-top: 1px solid #ccc;
        width: 100%;
        resize: none;
        position: relative;
        padding: 10px 40px;
        font-family: $defaultFont;

        .assistant-mobile-layout & {
            padding: 10px;
        }

        &:focus {
            outline: none;
        }
    }

    &-icon {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 25%;
        left: 3.5%;

        &-human {
            @extend %iconHuman;
            transform: translate(0, 1px);
        }
    }

    &-icon-cursor {
        @extend %iconCursor;
        transform: translate(0, 1px);
        position: absolute;
        top: 12%;
        right: 12%;
    }
}

.field-error {
    border-top: 1px solid red;
}
