@import '../../assets/styles/globals';
@import '../../assets/styles/icons';
$iconSize: 37px;

.single-message {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &.human {
       flex-direction: row-reverse;
    }
    &-icon {
        width: $iconSize;
        height: $iconSize;
        border-radius: 50%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: $defaultBoxShadow;
        img {
            max-width: 26px;
            max-height: 26px;
        }
        &-bot {
            @extend %iconBot;
            transform: translate(0, 1px);
        }
        &-human {
            @extend %iconHuman;
            transform: translate(0, 1px);
        }
    }
    &-text {
        width: calc(100% - #{$iconSize} - 15px);
        background: white;
        padding: 20px;
        font-size: 14px;
        line-height: 1.2;
        border-radius: $defaultBorderRadius;
        box-shadow: $defaultBoxShadow;
        border-bottom-left-radius: 0;
        .human & {
            color: white;
            background: $blueGradient;
            border-radius: $defaultBorderRadius;
            border-bottom-right-radius: 0;
        }
        img {
            width: 100%;
        }
    }
}
