@import '../../assets/styles/globals';

.top-product-tile {
    background: white;
    box-shadow: $defaultBoxShadow;
    width: 100%;
    padding: 40px;
    margin-bottom: 30px;
    cursor: pointer;

    &-body {
        display: flex;
        flex-wrap: wrap;

        &-image {
            width: 100%;

            @media(min-width: 768px) {
                width: 40%
            }

        }

        &-details {
            width: 100%;

            @media(min-width: 768px) {
                width: 60%
            }

            img {
                width: 30px;
            }

            &-first-section {
                font-size: 20px;

                &-price {
                    margin-left: 50px;
                }
            }

            &-first-section div, &-second-section {
                display: flex;
                justify-content: space-between;
            }

            &-second-section {
                margin-top: 20px;
                font-size:14px;

                ul {
                    margin-top: 10px;
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    img {
                        transform: translate(4px, 2px);
                        width: 17px;
                        margin-right: 5px;
                    }
                }
            }

        }
    }

    &-footer {

        button {
            background: transparent;
            border: none;
            box-shadow: none;
            text-transform: uppercase;

            &:focus {
                outline: none;
            }
        }

        .more-attrs {
            display: none;

            @media(min-width: 768px) {
                display: none;
                //display: flex;
                //align-items: center;
                //margin-left: auto;
                //margin-right: auto;
                //color: $colorPrimaryDark;
                //text-transform: uppercase;
            }

            img {
                margin-left: 5px;
            }
        }

        img {
            margin-right: 10px
        }

        .compare, .email-my-match {
            display: flex;
            align-items: center;

            img {
                margin-right: 5px
            }
        }

        .buy-now {
            background: $colorPrimary;
            width: 220px;
            height: 30px;
            color: white;
            font-weight: 600;
        }

        .email-my-match {
            color: $colorPrimaryDark;
            font-weight: 600;
        }

        &-icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 10px;
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column-reverse;

            button {
                width: 100%;
                cursor: pointer;
            }

            @media(min-width: 768px) {
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: flex-end;

                button {
                    width: auto;
                }
            }
        }
    }
}



