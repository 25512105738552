@import "../../assets/styles/reset";
@import "../../assets/styles/globals";

.message-list {
    padding: 30px 30px 0 30px;
    font-size: 12px;
    color: $colorGray;

    .assistant-mobile-layout & {
        padding: 10px;
    }
}
