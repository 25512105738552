@import '../../assets/styles/reset';
@import '../../assets/styles/globals';

.chat-container {
    &-messages {
        flex: 1;
        overflow: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }

    &-tags {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 30px;
            background: $shadowGradientTopToBottom
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 10px;
            height: 100%;
            background: $bgColor;
            box-shadow: $rightBoxShadow;
        }
    }
}
