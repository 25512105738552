@import '../assets/styles/reset';
@import '../assets/styles/globals';

$topHeight: 52px;
$chatColumnWidth: 400px;
$wrapperHeight: calc(100vh - #{$topHeight});

.desktop-layout {
    font-family: $defaultFont;

    &-top {
        height: $topHeight;
        box-shadow: $defaultBoxShadow;
        background: white;
        z-index: 10;
        position: relative;
    }

    &-wrapper {
        display: flex;
        height: calc(100vh - #{$topHeight});
        background: $bgColor;
    }

    &-chat-column {
        display: flex;
        flex-direction: column;
        width: $chatColumnWidth;
        height: $wrapperHeight;
        max-height: $wrapperHeight;
        padding: 0 0 30px 0;
        overflow: auto;
    }

    &-main-product-wrapper {
        width: calc(100% - #{$chatColumnWidth});
        height: $wrapperHeight;
        padding: 30px;
        background: white;
        overflow: scroll;
        overflow-x: hidden;
    }
}
