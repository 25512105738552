$bgImg: url('../images/icon_sprite.svg');

%iconBot {
    display: block;
    height: 22px;
    width: 22px;
    background: $bgImg no-repeat -46px 0;
}

%iconHuman {
    display: block;
    width: 31px;
    height: 31px;
    background: $bgImg no-repeat no-repeat -101px 0;
}

%iconRefresh {
    display: block;
    background: $bgImg no-repeat 0 0;
    width: 15px;
    height: 15px;
}

%iconRefresh {
    display: block;
    background: $bgImg no-repeat 0 0;
    width: 15px;
    height: 15px;
}

%iconLogo {
    display: block;
    background: $bgImg no-repeat 0 -36px;
    width: 112px;
    height: 25px;
}

%iconBasket {
    display: block;
    background: $bgImg no-repeat -137px 0;
    width: 18px;
    height: 17px;
}

%iconMagnifyGlass {
    display: block;
    background: $bgImg no-repeat -159px 0;
    width: 17px;
    height: 17px;
}

%iconCursor {
    display: block;
    width: 21px;
    height: 20px;
    background: $bgImg no-repeat -20px 0;
}

%iconBuyArrow {
    display: block;
    width: 11px;
    height: 11px;
    background: $bgImg no-repeat -197px -36px;
}

%iconMicrophoneOff {
    display: block;
    width: 18px;
    height: 31px;
    background: $bgImg no-repeat -14px -136px;
}

%iconMicrophoneOn {
    display: block;
    width: 18px;
    height: 31px;
    background: $bgImg no-repeat -36px -136px;
}

%iconGrayClose {
    display: block;
    width: 9px;
    height: 9px;
    background: $bgImg no-repeat 0 -136px;
}

