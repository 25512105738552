@import "../../assets/styles/reset";
@import "../../assets/styles/globals";
@import "../../assets/styles/icons";

.flip-to-portrait-modal {
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    color: black;

    @media (orientation: landscape) {
        display: flex;
    }

    img {
       max-width: 100px;
    }

}
