@import '../assets/styles/reset.scss';
@import '../assets/styles/globals.scss';
@import '../assets/styles/icons.scss';

.voice-recognition-switcher {
    &-wrapper {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        position: fixed;
        display: flex;
        flex-direction: column;
        bottom: 20px;
        right: 40px;
        z-index: 100;

        .assistant-mobile-layout & {
            bottom: 28px;
            right: 20px;
        }
    }

    &-button {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        font-size: 30px;
        width: 60px;
        height: 60px;
        line-height: 1;
        border-radius: 50%;
        outline: none;
        background: white;
        color: black;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        box-shadow: $defaultBoxShadow;

        .assistant-mobile-layout & {
            width: 40px;
            height: 40px;
        }

        &.on {
            background: $colorPrimary;
            animation: listening-animation 10s infinite;
            animation-timing-function: ease-in-out;
        }

        &-icon {
            @extend %iconMicrophoneOff;
            padding-left: 2px;
            .on & {
                @extend %iconMicrophoneOn;
            }

            .assistant-mobile-layout & {
                transform: scale(0.8);
            }
        }

        &-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-last-message {
        margin: 10px 0 0 20px;
        padding: 6px;
        border: 1px solid #ccc;
        border-radius: 5px 0 5px 5px;
        background: #fff;
    }
}

@keyframes listening-animation {
    0% {
        box-shadow: 0 0 0 0 rgba(2, 164, 239, 0.5);
    }
    20% {
        box-shadow: 0 0 0 40px rgba(2, 164, 239, 0.3);
    }
    40% {
        box-shadow: 0 0 0 10px rgba(2, 164, 239, 0.6);
    }
    60% {
        box-shadow: 0 0 0 50px rgba(2, 164, 239, 0.2);
    }
    80% {
        box-shadow: 0 0 0 5px rgba(2, 164, 239, 0.5);
    }
    100% {
        box-shadow: 0 0 0 40px rgba(2, 164, 239, 0);
    }
}
