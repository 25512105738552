@import '../assets/styles/globals.scss';

.voice-modal {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    z-index: 99999999;

    h2 {
        font-size: 20px;
        line-height: 1.4;
    }
}

.voice-modal.off {
    display: none;
}

.voice-modal-container {
    max-width: 400px;
    padding: 20px;
    text-align: center;
}

.voice-modal-button {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
    outline: none;
    background: $colorPrimary;
    color: white;
    border: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.voice-modal-button:hover {
    transform: scale(1.2);
}
