@import "../assets/styles/reset";
@import "../assets/styles/globals";
@import "../assets/styles/icons";

$upperDistance: calc(66.666vw + 36px);
$upperDistanceLimited: 46px;

.assistant-mobile-layout {
    font-family: $defaultFont;
    display: flex;
    flex-direction: column;
    background: $bgColor;
    min-height: 100vh;

    &-header-part {
        position: fixed;
        top: 0;
        width: 100%;
        overflow: hidden;
        background: white;
        z-index: 999;
        height: $upperDistance;
        transition: height 0.2s ease-in-out;
        box-shadow: $bigBottomBoxShadow;

        &.off {
            height: 171px;
        }
    }

    &-main-product-wrapper {
        padding: 4px;
    }

    &-messages-wrapper {
        padding-top: $upperDistance;
        padding-bottom: 10vh;
        transition: padding-top 0.2s ease-in-out;

        &.off {
            padding-top: 171px;
        }
    }

    &-footer-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 10px;
        background: $bgColor;
    }

    &-product-toggle-button {
        position: fixed;
        top: 60px;
        right: 10px;
        background: $colorPrimary;
        box-shadow: $defaultBoxShadow;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .3s ease-in-out;
        &.on {
            transform: rotate(180deg);
        }
    }

    .product-list {
        background: white;
    }
}
