// font
$defaultFont: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;

// colors
$colorPrimary: #02A4EF;
$colorPrimaryDark: #1e72be;
$colorGray: #979797;
$colorDarkGray: #333;
$colorLightGray: #F5F8F9;
$bgColor: $colorLightGray;

// gradients
$shadowGradientTopToBottom: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(245,248,249,0) 100%);
$shadowGradientBottomToTop: linear-gradient(to bottom, rgba(245,248,249,0) 0%, rgba(245,248,249,1) 100%);
$blueGradient: linear-gradient(180deg, #63C8F7 0%, #02A4EF 100%);

// other
$defaultBorderRadius: 5px;
$defaultBoxShadow: 2px 2px 6px 0 rgba(0,0,0,0.1);
$selectedBoxShadow: 2px 2px 6px 0 rgba($colorPrimary, 0.1);
$rightBoxShadow: 2px -6px 6px 0 rgba(0,0,0,0.1);
$leftBoxShadow: -2px -6px 6px 0 rgba(0,0,0,0.1);
$bigBottomBoxShadow: 0px 0px 10px 0 rgba(0,0,0,0.2);
//break points
$xlarge: 1280px;
$large: 980px;
$medium: 736px;
$small: 480px;
