img.branding {
    margin: 16px 6px 16px 10px ;
    width: 108px;
    height: 23px;
}

div.main-title {
    color: #262626;
    padding-left: 24px;
}

div.main-title span {
    font-weight: bold;
    padding-left: 12px;;
}

div.main-title::before {
    content: '|';
    margin-left: -12px;
    font-size: 24px;
}
