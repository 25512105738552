@import "../../assets/styles/reset";
@import "../../assets/styles/globals";
@import "../../assets/styles/icons";

$header-distance-unit: 20px;


.header {
    height: inherit;
    display: flex;
    align-items: center;
    padding: 10px;

    @media screen and (min-width: $medium) {
        padding: $header-distance-unit 3*$header-distance-unit;
    }

    &-logo {
        margin-right: 2 * $header-distance-unit;
        cursor: pointer;
        color: $colorPrimary;

        &-icon {
            @extend %iconLogo
        }
    }
    &-nav {
        display: none;
        margin-left: 20px;

        @media screen and (min-width: $medium) {
            display: block;
        }

        &-list {
            display: flex;
            align-items: center;
        }
        &-list-item {
            margin-right: 2 * $header-distance-unit;
            font-weight: 600;
            cursor: pointer;
            &:hover {
                color: $colorPrimary;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &-right-section {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    &-search {
        margin-right: $header-distance-unit;
        position: relative;
        display: none;

        @media screen and (min-width: $large) {
            display: block;
        }

        &-input {
            font-family: $defaultFont;
            border: 1px solid #ccc;
            height: 30px;
            width: 200px;
            padding: 0 10px;
        }
        &-icon {
            @extend %iconMagnifyGlass;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(0, -50%);
            pointer-events: none;
        }
    }
    &-basket {
        display: flex;
        align-items: center;
        margin-right: $header-distance-unit;
        font-size: 0.8em;
        &-icon {
            @extend %iconBasket;
            margin-right: 2px;
            transform: scale(0.8);
        }
    }
    &-sign-in {
        &-link  {
            &:hover {
                color: $colorPrimary;
            }
        }
    }
}
