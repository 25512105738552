@import '../../assets/styles/globals';

.top-product-mobile-tile {
    position: relative;
    padding-bottom: 63%;
    font-size: 4vw;

    &-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

    &-name {
        padding-right: 50px;
    }

    &-image {
        width: 80%;

        &-wrapper {
            padding-top: 10px;
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    &-price {
        margin-top: 10px;
    }

    &-content {
        &-wrapper {
            padding-top: 10px;
            width: 60%;
        }
    }

    &-attributes {
        margin-top: 10px;
        font-size: 0.8em;

        &-item {
            line-height: 1.2;
            padding-left: 10px;
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 3px;
                height: 3px;
                background: black;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }

    &-button {
        background: $colorPrimary;
        border: 0;
        color: white;
        text-transform: uppercase;
        font-size: 0.6em;
        padding: 10px 22px;
        &-wrapper {
            margin-top: 10px;
        }
    }
}



